// Customizable Area Start
import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AccordionDetails,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { HomeOutlined, PersonOutlined,ChatBubbleOutline } from "@material-ui/icons";
import "./Navigation.css";
import { NavLink} from "react-router-dom";
import {BookingIcon, ClientsIcon, SkyCardIcon} from "./assets";
import {SidebarAccordion, MyAccordionSummary, MuiModal, StatusModal, MuiResuableStatusModalBody, renderDeleteIconContainer, AddLegtTypeButton, FormSubmitButton} from "../../../components/src/MuiCustomComponents"



import SidebarController, {
  Props,
} from "./NavigationMenuController";



const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '40px',
});

const SubAccordionSummary = styled(MyAccordionSummary)({
  backgroundColor : 'inherit',
  width : '115%',
  padding : '0'
});

enum Role {
  Admin = 'admin',
  User = 'user'
}

export default class Sidebar extends SidebarController {
  constructor(props: Props) {
    super(props);

  }


        renderBooking = () => {
          const {role,isUpcomingBookingTouched} = this.state;
          const isUserRole = role === 'user';
          return (
            <SidebarAccordion>
                <MyAccordionSummary
                  style={webStyle.accordionStyle}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <StyledListItemIcon>
                      <BookingIcon/>
                  </StyledListItemIcon>
                  <ListItemText primary='Bookings' className='sidebar_item_text' />
                </MyAccordionSummary>
                <AccordionDetails
                  className='nav_accord_details'
                  style={{...webStyle.inside_accord,marginTop:'1rem'}}
                >
                  <List>
                    {!isUserRole && this.renderEachSubMenu('/AdvancedSearch','Create Bookings',true,()=>this.handleCreateBooking(),'avinode_integration')}
                        <NavLink to={'/OrderDetailsView'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="upcoming_bookings" isActive={()=>this.handlePreviousAndUpcomingHighlight('/OrderDetailsView',isUpcomingBookingTouched)} onClick={()=>this.handleUpcomingAndPreviousToched(true)}>
                        <ListItem className='nav_list_item' button>
                          <ListItemText primary='Upcoming Bookings' />
                        </ListItem>
                    </NavLink>

                    <NavLink to={'/PreviousBookings'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="previous_bookings" isActive={()=> this.handlePreviousAndUpcomingHighlight('/PreviousBookings',!isUpcomingBookingTouched)} onClick={()=>this.handleUpcomingAndPreviousToched(false)}>
                        <ListItem className='nav_list_item' button>
                          <ListItemText primary='Previous Bookings' />
                        </ListItem>
                    </NavLink>
                  </List>
                </AccordionDetails>
            </SidebarAccordion>
          )
      }

      renderClientBooking = () => {
        const { isClientUpcomingBookingTouched } = this.state;
        return (
          <SidebarAccordion>
              <MyAccordionSummary
                style={webStyle.accordionStyle}
                id='panel1a-header'
                aria-controls='panel1a-content'
              >
                <StyledListItemIcon>
                    <BookingIcon/>
                </StyledListItemIcon>
                <ListItemText primary='Bookings' className='sidebar_item_text' />
              </MyAccordionSummary>
              <AccordionDetails
                className='nav_accord_details'
                style={{...webStyle.inside_accord,marginTop:'1rem'}}
              >
                <List>
                      <NavLink to={'/ClientOrderDetailsView'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="upcoming_bookings" isActive={()=>this.handlePreviousAndUpcomingHighlight('/ClientOrderDetailsView',isClientUpcomingBookingTouched)} onClick={()=>this.handleClientUpcomingAndPreviousToched(true)}>
                      <ListItem className='nav_list_item' button>
                        <ListItemText primary='Upcoming Bookings' />
                      </ListItem>
                  </NavLink>

                  <NavLink to={'/ClientPreviousBookings'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="previous_bookings" isActive={()=> this.handlePreviousAndUpcomingHighlight('/ClientPreviousBookings',!isClientUpcomingBookingTouched)} onClick={()=>this.handleClientUpcomingAndPreviousToched(false)}>
                      <ListItem className='nav_list_item' button>
                        <ListItemText primary='Previous Bookings' />
                      </ListItem>
                  </NavLink>
                </List>
              </AccordionDetails>
          </SidebarAccordion>
        )
      }

      renderSkyCardsTab = () => {
        return (
          <SidebarAccordion>
            <MyAccordionSummary
              style={webStyle.accordionStyle}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <StyledListItemIcon>
                <SkyCardIcon />
              </StyledListItemIcon>
              <ListItemText primary="Sky Cards" className="sidebar_item_text" />
            </MyAccordionSummary>
            <AccordionDetails
              className="nav_accord_details"
              style={{ ...webStyle.inside_accord, marginTop: "1rem" }}
            >
              <List>
                {this.renderEachSubMenu("/ClientDebitSkyCards", "Debit Sky Cards")}
                {this.renderEachSubMenu("/Client25HourSkyCards", "25 Hour Sky Cards")}
                {this.renderEachSubMenu("/ClientCustomSkyCards", "Custom Sky Cards")}
              </List>
            </AccordionDetails>
          </SidebarAccordion>
        );
      };
      
      
      renderGeneralSideTab = (navigateTo:string,title:string,icon:React.ReactNode,classTitle= '',isOneTab = false) => {
        const generateNavLinkProps = (handleHighlightClientsTab:(navigateTo: string) => boolean) => {
          const navLinkProps = {
              to: navigateTo,
              activeClassName: `sidebar_active_element ${classTitle}`,
              className: 'sidebar_unactive_element',
              id: 'navbar',
              exact: true,
          };
  
          if (!isOneTab) {
              Object.assign(navLinkProps, {
                  isActive: () => handleHighlightClientsTab(navigateTo)
              });
          }
  
          return navLinkProps;
      };

          return (
            <NavLink {...generateNavLinkProps(this.handleHighlightClientsTab)}>
            <ListItem button className='sidebar_menu_item'>
                
                <StyledListItemIcon>
                 {icon}
                </StyledListItemIcon>
                <ListItemText primary={title} className='sidebar_item_text' />

            </ListItem>
            </NavLink>
          )
      }

   

     

      renderEachSubMenu=(pathToNavigate:string,menuName:string,isActive=false,isActiveCallBack=()=>false,idName:string | boolean=false)=>{
        return <NavLink to={pathToNavigate} activeClassName="sidebar_active_element" className='sidebar_unactive_element' 
        {...(isActive && { isActive: ()=>isActiveCallBack()})}
        {...(idName && { id: idName as unknown as string})}
        >
        <ListItem className='nav_list_item' button>
          <ListItemText primary={menuName} />
        </ListItem>
      </NavLink>
      }

      renderHelpCentreWithMenu=()=>{
        const {role} = this.state;
        const isUser = role === 'user';
        return  <SidebarAccordion>
        <SubAccordionSummary
          
          style={webStyle.accordionStyle}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <ListItemText primary='Help Centre' />
        </SubAccordionSummary>
        <AccordionDetails
          
          className='nav_accord_details'
          style={{...webStyle.inside_accord,transition : 'none'}}
        >
          <List>
            {this.renderEachSubMenu(this.conditionHandler(!isUser,'/Contactus','/ClientContactus') as string,'Contact Us')}
            {this.renderEachSubMenu('/FAQ','FAQs',true, ()=>this.handleFAQs(),'faq_handle')}
            {this.renderEachSubMenu('/PrivacyPolicy','Privacy Policy',true,()=>this.handlePrivacyEdit(),'privacy-policy')}
            {this.renderEachSubMenu('/TermsConditions','Terms',true,()=>this.handleTermsConditionsEdit(),'terms-conditions')}
          
          </List>
        </AccordionDetails>
      </SidebarAccordion>
    }


      
      renderAccount = (isAdmin: boolean) => {
          return (
            <SidebarAccordion style={{ boxShadow: "none" }}>
                <MyAccordionSummary
                  style={webStyle.accordionStyle}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  
                >
                  <StyledListItemIcon>
                      <PersonOutlined color={"primary"} />
                  </StyledListItemIcon>
                  <ListItemText primary='Account' className='sidebar_item_text' />
                </MyAccordionSummary>
                <AccordionDetails
                
                  className='nav_accord_details'
                  style={{...webStyle.inside_accord,marginTop:"1rem"}}
                >
                  <List>


                      {this.renderEachSubMenu('/userProfileBasicBlock','Profile')}
                      {!isAdmin && this.renderUserAccountOptions()}
                      <ListItem className='nav_list_item sidebar_list_nav_list_item_menu'>{this.renderHelpCentreWithMenu()}</ListItem>
                      <ListItem className='nav_list_item sidebar_logout' button onClick={this.handleLogout}>
                        <ListItemText primary='Log Out' />
                      </ListItem>
                       {isAdmin && <ListItem className='nav_list_item side_del_modal' button onClick={()=>this.handleDeleteAccountModal(true)}>
                        <ListItemText
                            primary='Delete Account'
                            className='sidebar_delete_acc'
                        />
                      </ListItem>}
                  </List>
                </AccordionDetails>
            </SidebarAccordion>
          )
      }

      renderUserAccountOptions=()=>{
        return <>
          {this.renderEachSubMenu('/SavedPassenger','Saved Passengers')}
          {this.renderEachSubMenu('/Cfpaymentgateway33','Payment Methods')}
          {this.renderEachSubMenu('/Notifications','Notifications')}
        </>
      }

      renderSuperAdmin = () => {
        return (
          <SidebarAccordion style={{ boxShadow: "none" }}>
              <MyAccordionSummary
                style={webStyle.accordionStyle}
                aria-controls='panel1a-content'
                id='panel1a-header'
                
              >
                <StyledListItemIcon>
                    <ClientsIcon  />
                </StyledListItemIcon>
                <ListItemText primary='Super Admin' className='sidebar_item_text' />
              </MyAccordionSummary>
              <AccordionDetails
              
                className='nav_accord_details'
                style={webStyle.inside_accord}
              >
                <List>
                {this.renderEachSubMenu('/AdminList','Admin Accounts',true,()=>this.handleAdminListHighlight(),'adminlist')}
                {this.renderEachSubMenu('/SystemSettings','System Settings')}
                {this.renderEachSubMenu('/JobTitles','Job Titles',undefined,undefined,'sidebar_logout_button')}
                {this.renderEachSubMenu('/PaymentSetting','Payment Settings',undefined,undefined,'sidebar_logout_button')}
                </List>

               
              </AccordionDetails>
          </SidebarAccordion>
        )
    }
 

  renderDeleteAccountConfirmModal=()=>{
    return <MuiModal
    open={this.state.isDeleteAccountModalOpen}
    >
      <StatusModal className="status_update_modal_delete">
          <MuiResuableStatusModalBody
            heading = {'Delete Account'}
            subHeading = {'Are you sure? This action is permanent.'}
            iconCont = {()=>renderDeleteIconContainer()}
          >
            <Box style={{display:"flex",justifyContent:"space-between",width:"30rem",columnGap:"1rem"}}>
                  <AddLegtTypeButton className = 'yes_button' style={{width:'50rem',color:'#F87171',border:'1px solid #F87171'}} fullWidth onClick={this.deleteAccount}>Yes</AddLegtTypeButton>
                    <FormSubmitButton style={{width:'50rem'}} fullWidth  className="no_button" onClick={()=>this.handleDeleteAccountModal()}>No</FormSubmitButton>
                  </Box>
          </MuiResuableStatusModalBody>
                    
      </StatusModal>
    </MuiModal>
  }

  render() {
    const {role} = this.state;
    const isUserRole = role === 'user';
    const isAdmin = !isUserRole;
    return (
        <Box  className="sidebar_parent_container">
          <List component="nav" aria-label="main mailbox folders" className="sidebar_list_item_cont">
              {this.renderGeneralSideTab('/','Dashboard',<HomeOutlined color={"primary"} />)}
              {isAdmin && this.renderBooking()}
              {isUserRole && this.renderClientBooking()}
              {isUserRole && this.renderSkyCardsTab()}
              {isAdmin && this.renderGeneralSideTab('/Clients','Clients',<ClientsIcon  />,'custom_imported_icon')}
              {this.renderAccount(isAdmin)}  
              {isAdmin && this.renderSuperAdmin()}
              {isAdmin && this.renderDeleteAccountConfirmModal()}
          </List>
        </Box>
    );
  }
}

const webStyle = {
  accordionStyle: {
    margin:"0",
  },
  inside_accord:{
    color:"white"
  },
  skyCardAccordian:{
    margin:0,
    gap:"1.5rem",
  }
};
// Customizable Area End