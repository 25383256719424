import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
import { handleNavigation } from "../../../components/src/Navigator";
import { getStorageData } from "../../../framework/src/Utilities";

type NullString = null | string
export interface PaymentFormType{
    name : NullString,
    fedwire_routing : NullString,
    account_number : NullString,
    account_type : NullString,
    rec_address : NullString,
    zip_code : NullString
}
interface PaymentForm{
    name : string,
    fedwire_routing : string,
    account_number : string,
    account_type : string,
    rec_address : string,
    zip_code : string
}


interface PaymentSettings {
    id: string;
    type: string;
    attributes: {
        id: number;
        organisation_name: string;
        fedwire_routing_number: string;
        account_number: string;
        account_type: string;
        recipient_address: string;
        zipcode: NullString;
        kind: string;
    };
}

const payment_setting_val = {
    name : null,
    fedwire_routing : null,
    account_number : null,
    account_type : null,
    rec_address : null,
    zip_code : null
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    isEditState:boolean;
    payment_form:PaymentFormType;
    activeLabel:string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PaymentSettingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    paymentFormik:null | FormikProps<PaymentFormType> = null;
    paymentSettingsApiCallId: string = ''
    updatePaymentApiCallId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            isEditState:false,
            payment_form : {
                name : null,
                fedwire_routing : null,
                account_number : null,
                account_type : null,
                rec_address : null,
                zip_code : null
            },
            activeLabel:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.paymentSettingsApiCallId) {
                if (responseJson.data)
                    this.handleGetPaymentSettings(responseJson.data)
            }else if (apiRequestCallId === this.updatePaymentApiCallId) {
            this.handleUpdatePaymentSettings(responseJson.data)
            }
          }
        // Customizable Area End
    }



    // Customizable Area Start
    async componentDidMount() {
         this.handleSavePaymentSettingsApi();
    }
    handleActiveLabel=(activeLabel="")=>{
        this.setState({activeLabel});
    }
    handleGetPaymentSettings = (responseJson:PaymentSettings)=>{
        this.setState({payment_form:{
            name : responseJson.attributes.organisation_name,
                fedwire_routing : responseJson.attributes.fedwire_routing_number,
                account_number : responseJson.attributes.account_number,
                account_type : responseJson.attributes.account_type,
                rec_address : responseJson.attributes.recipient_address,
                zip_code : responseJson.attributes.zipcode
        }})
    }
    handleEditState=(isEditState=false)=>{
        this.setState({isEditState});
    }

    handleBack=()=>{
        const {isEditState} = this.state;
        if(isEditState) this.cancelSave();
        else handleNavigation('/',this.props,this.send);
    }

    cancelSave=()=>{
        this.handleEditState();
        this.paymentFormik?.resetForm();
    }
    handleUpdatePaymentSettings = (response:any)=>{
        if(response){
            this.handleEditState();
        }
    }
    handleSubmit = async (values: PaymentFormType | undefined)=>{
        const header = {
            token : await getStorageData('token')
          };
        const formData = new FormData();
        formData.append("data[organisation_name]", values?.name || "");
        formData.append("data[fedwire_routing_number]", values?.fedwire_routing || "");
        formData.append("data[account_number]", values?.account_number || "");
        formData.append("data[account_type]", values?.account_type || "");
        formData.append("data[zipcode]", values?.zip_code || "");
        formData.append("data[recipient_address]", values?.rec_address || "");
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
         this.updatePaymentApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updatePaymentHeader
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updatePaymentmethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    
    }
    handleSavePaymentSettingsApi = async() => {
        const header = {
            token : await getStorageData('token')
          };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.paymentSettingsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAdminPaymentSettingEndpoint 
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}